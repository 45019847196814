import React from "react";

import "./styles.css";

const SingleServiceCard = (props) => {
  return (
    <div className="service_card">
      <img src={props.img} alt="" />
      <p>{props.title}</p>
      <a href="">Read More</a>
    </div>
  );
};

export default SingleServiceCard;
