import React from "react";

import "./styles.css";
import SingleServiceCard from "../SIngleServiceCard";

import fire_img from "../../assets/img/slider/fire_mcp_bg.jpg";
import bms_img from "../../assets/img/slider/bms.webp";
import resp from "../../assets/img/slider/resp.jpg";
import gas_img from "../../assets/img/slider/gas.jpg";
import bms from "../../assets/img/slider/fire_panel.jpg";

const OurService = () => {
  return (
    <div className="container our_service_container text-center" id="services">
      <h1 className="section_title">Our Services</h1>

      <div className="service_section">
        <div className="row">
          <div className="col-md-3">
            <SingleServiceCard title="Fire Detection System" img={fire_img} />
          </div>
          <div className="col-md-3">
            <SingleServiceCard
              title="Fire Protection System"
              img="https://www.honeywellbuildings.in/uploads/fire_safety/category/1605807652fire_prd2.jpg"
            />
          </div>
          <div className="col-md-3">
            <SingleServiceCard title="Building Management System" img={bms} />
          </div>
          <div className="col-md-3">
            <SingleServiceCard title="Gas Detection System" img={gas_img} />
          </div>
          <div className="col-md-3">
            <SingleServiceCard
              title="PPE"
              img="https://ppe.honeywell.com/content/dam/pped2cwebsite/en/images/categories/sps-ppe-retail-category-head-face-protection-feature-image.jpg"
            />
          </div>
          <div className="col-md-3">
            <SingleServiceCard
              title="Electrical Safety"
              img="https://www.safetyandhealthmagazine.com/ext/resources/images/products/On-Display-2017/071417/Honeywell.jpg?t=1500050990&width=696"
            />
          </div>
          <div className="col-md-3">
            <SingleServiceCard title="Respiratory Protection" img={resp} />
          </div>
          <div className="col-md-3">
            <SingleServiceCard
              title="Fall Protection"
              img="https://5.imimg.com/data5/SELLER/Default/2022/5/NM/CD/RW/17024921/ho-1032097-1032098-1032099-honeywellmiller-handzup-workpositioning-lanyard-hoehenpass-halteseile3-500x500.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurService;
